<script lang="ts" setup>
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {computed, getCurrentInstance, onBeforeMount, watch} from 'vue';
import type {QuestionMultiChoice} from '@/ts/types/dto/interventions-dto';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import {calculateNumber} from '@/ts/composables/pure/use-question-slider';
import InputSlider from '@/vue/molecules/input-slider.vue';
import {
  type InputSliderItem,
  InputSliderMarkerStyle,
  InputSliderSelectionStyle,
} from '@/ts/types/component/input-slider.type';

const model = defineModel<UuidDTO[]>();
const props = defineProps<{
  question: QuestionMultiChoice;
}>();

const {warn, debug} = useLogger();

const instance = getCurrentInstance();

const options = computed(() => props.question.multiChoice.options);

const sliderItems = computed((): InputSliderItem[] => {
  const existingValues: number[] = [];
  return options.value.map((option) => {
    if (existingValues.includes(option.value)) {
      warn(`Value of Slider-Item "${option.value}" already exists. The repeats get filtered out.`)();
      return null;
    }
    existingValues.push(option.value);
    return {
      label: option.text,
      value: option.value,
    };
  })
    .filter<InputSliderItem>((item) => (item !== null))
    .sort((a, b) => (a.value - b.value));
});

function valueToId(input: number): UuidDTO[] {
  return [options.value.find(op => op.value === input)!.id];
}

const val = computed<number>({
  get(): number {
    return calculateNumber(options.value, model.value, props.question) ?? sliderItems.value[0].value ?? 0;
  },
  set(newValue) {
    model.value = valueToId(newValue);
  },
});

const image = computed(() => options.value.find(op => op.value === val.value)?.image);

const sliderLabelId = computed((): string => {
  return `slider-label-${  instance?.uid}`;
});

onBeforeMount(() => {
  debug(`onBeforeMount ${props.question.contentId}`)();
  model.value = valueToId(val.value); // emit value to the parents
});

watch(() => props.question.contentId, () => {
  debug(`watch ${props.question.contentId}`)();
  model.value = valueToId(val.value); // emit value to the parents
});
</script>
<template>
  <div class="question-slider">
    <strong :id="sliderLabelId" class="question">{{ question.name }}</strong>
    <div v-if="image" class="img">
      <lazy-image :src="image" :height="132" skip-loader class="image"/>
    </div>

    <input-slider
      v-model="val"
      class="input-slider"
      :items="sliderItems"
      :marker-style="InputSliderMarkerStyle.DISPLAY_VALUE"
      :selection-style="InputSliderSelectionStyle.VALUE_WITH_LABEL"
      :labelled-by="sliderLabelId"
    />
  </div>
</template>
<style scoped lang="scss">
.question {
  margin: var(--Spacing-spacing-2) 0;
  display: block;
}

.image {
  border-radius: var(--card-border-radius);
}

.img {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--Spacing-spacing-8) 0;
}

</style>
