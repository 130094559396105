<script lang="ts" setup>
import {
  type Component,
  type PrimaryFeeling,
  type ThinkingTrap,
  ComponentType,
} from '@/ts/types/dto/interventions-dto';
import ReadOnlyContent from '@/vue/molecules/read-only/read-only-content.vue';
import ReadOnlyJournal from '@/vue/molecules/read-only/read-only-journal.vue';
import ReadOnlyConceptualization from '@/vue/molecules/read-only/read-only-conceptualization.vue';
import ReadOnlyTable from '@/vue/molecules/read-only/read-only-table.vue';
import ReadOnlyQuestionnaire from '@/vue/molecules/read-only/questionnaire/read-only-questionnaire.vue';
import ReadOnlyProblemSolving from '@/vue/molecules/read-only/read-only-problem-solving.vue';
import ErrorBox from '@/vue/molecules/error-box.vue';
import ReadOnlyLayout from '@/vue/molecules/read-only/read-only-layout.vue';

defineProps<{
  component: Component;
  primaryFeelings: PrimaryFeeling[];
  thinkingTraps: ThinkingTrap[];
}>();

</script>
<template>
  <read-only-content
    v-if="component.type === ComponentType.CONTENT"
    :content="component.content"
  />
  <read-only-journal
    v-else-if="component.type === ComponentType.JOURNAL"
    :journal="component.journal"
  />
  <read-only-conceptualization
    v-else-if="component.type === ComponentType.CONCEPTUALIZATION_WORKSHEET"
    :worksheet="component.conceptualizationWorksheet"
    :primary-feelings="primaryFeelings"
    :thinking-traps="thinkingTraps"
  />
  <read-only-table
    v-else-if="component.type === ComponentType.TABLE"
    :table="component.table"
  />
  <read-only-questionnaire
    v-else-if="component.type === ComponentType.QUESTIONNAIRE"
    :questionnaire="component.questionnaire"
  />
  <read-only-content
    v-else-if="component.type === ComponentType.QUESTIONNAIRE_REVIEW && component.questionnaireReview.content"
    :content="component.questionnaireReview.content"
  />
  <read-only-problem-solving
    v-else-if="component.type === ComponentType.PROBLEM_SOLVING_WORKSHEET"
    :worksheet="component.problemSolvingWorksheet"
  />
  <read-only-layout
    v-else-if="component.type === ComponentType.LAYOUT"
    :layout="component.componentLayout"
  />
  <error-box v-else/>
</template>
