<script setup lang="ts">
import BalmLabel from '@/vue/molecules/balm-label.vue';
import type {Score} from '@/ts/types/dto/interventions-dto';

defineProps<{
  score: Score;
}>();
</script>
<template>
  <div class="questionnaire-score">
    <div class="results">
      <balm-label theme="blue" :label="$t('results')" class="label"/>
      <div class="res-body">
        {{ score.score }} - {{ score.label }}
      </div>
    </div>
    <div class="interpretation">
      <balm-label theme="blue" :label="$t('interpretation')" class="label"/>
      <div class="interpretation-body" v-html="score.summary"/>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.questionnaire-score {
  @include screen-md {
    gap: var(--Spacing-spacing-5);
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
}

.results {
  margin-bottom: var(--Spacing-spacing-5);
}

.label {
  width: fit-content;
  margin-bottom: var(--Spacing-spacing-2);

  @include screen-md {
    margin-bottom: var(--Spacing-spacing-5);
  }
}

.res-body {
  background-color: var(--Primary-primary-color-2);
  border-radius: var(--button-border-radius);
  color: var(--Neutral-UI-neutral-ui-10);
  padding: var(--Spacing-spacing-3) var(--Spacing-spacing-7);
  width: fit-content;
  height: fit-content;
}

</style>
