<!--

Implements the ARIA listbox pattern.
https://www.w3.org/WAI/ARIA/apg/patterns/listbox/

In order to style the option-items set custom classnames to 'optionClassName' and 'optionActiveClassName'
and add the stylesheet in the parent component with "&:deep(.your-class)"

Example:
<aria-list-box
  class="listbox"
  option-class-name="listbox__option"
  option-active-class-name="listbox__option--active"
  ...
>
  <template #item="templateProps">
    {{ templateProps.option.your-property }}
  </template>
</aria-list-box>

.listbox {
  &:deep(.listbox__option) {
    ...
  }

  &:focus-visible:deep(.listbox__option--active) {
    ...
  }
}

-->

<script lang="ts" setup>
/* eslint-disable max-lines */
import {
  computed,
  ref,
} from 'vue';
import {getRandomId} from '@/ts/utils/pure-functions';
import type {ListBoxOption} from '@/ts/types/component/aria-list-box.type';

interface AriaBoxProps {
  options: ListBoxOption[];
  optionClassName?: string;
  optionActiveClassName?: string;
  optionSelectedClassName?: string;
  orientation: 'horizontal' | 'vertical' | 'ambiguous'; // use 'ambiguous' if the orientation can change by styling
  maximumSelectable?: number | null;
}

const props = withDefaults(defineProps<AriaBoxProps>(), {
  optionClassName: 'listbox__option',
  optionActiveClassName: 'listbox__option--active',
  optionSelectedClassName: 'listbox__option--selected',
  maximumSelectable: null,
});

const modelValue = defineModel<string[]>({
  required: true,
});

const currentIndex = ref<number>(0);
const focused = ref<boolean>(false);
const optionElIdPrefix = `listbox-${getRandomId()}-option-`;

const activeDescendantId = computed((): string | null => props.options[currentIndex.value].id || null);

const singleSelect = computed((): boolean => props.maximumSelectable === 1);

function onSelectOption(index: number): void {
   const {id} = props.options[index];
   if (modelValue.value.includes(id)) {
    modelValue.value = modelValue.value.filter((el) => el !== id);
  } else if (singleSelect.value) {
     modelValue.value = [id];
  } else if (!props.maximumSelectable || props.maximumSelectable > modelValue.value.length) {
     modelValue.value = [...modelValue.value, id];
  }
};

function focusButton(requestedIndex: number): void {
  if (requestedIndex >= 0 && requestedIndex < props.options.length) {
    currentIndex.value = requestedIndex;
  }
};

function moveSelection(desiredCheck: 'horizontal' | 'vertical', to: -1 | 1): void {
  // In some components the orientation changes depending on CSS breakpoints.
  // These components should support horizontal and vertical arrow-keys
  if (props.orientation === desiredCheck || props.orientation === 'ambiguous') {
    focusButton(currentIndex.value + to);
  }
}

defineSlots<{
  item(props: {option: ListBoxOption; isSelected: boolean}): any;
}>();

function onFocus(): void {
  focused.value = true;
  const index = props.options.findIndex((option) => modelValue.value.includes(option.id));
  focusButton(Math.max(index, 0));
};

function onBlur(): void {
  focused.value = false;
};

function getSelectionClass(id: string, index: number): Record<string, boolean> {
  return {
    [props.optionClassName]: true,
    [props.optionSelectedClassName]: modelValue.value.includes(id),
    [props.optionActiveClassName]: focused.value && index === currentIndex.value,
  };
}

</script>

<template>
  <div
    ref="listBoxEl"
    v-move-selection="moveSelection"
    class="listbox"
    role="listbox"
    tabindex="0"
    :aria-multiselectable="!singleSelect"
    :aria-activedescendant="optionElIdPrefix + activeDescendantId"
    :aria-orientation="orientation === 'ambiguous' ? undefined : orientation"
    @keydown.space.prevent.stop="onSelectOption(currentIndex)"
    @keydown.home.prevent.stop="focusButton(0)"
    @keydown.end.prevent.stop="focusButton(options.length - 1)"
    @focus="onFocus"
    @blur="onBlur"
  >
    <div
      v-for="(option, index) in options"
      :id="optionElIdPrefix + option.id"
      :key="option.id"
      v-ripple.center
      :class="getSelectionClass(option.id, index)"
      role="option"
      type="button"
      :aria-selected="modelValue.includes(option.id)"
      @click="onSelectOption(index)"
    >
      <slot name="item" :option="option" :is-selected="modelValue.includes(option.id)"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>

[role=option] {
  position: relative;
  overflow: hidden;
}

.listbox {
  outline: none;
}

:focus-visible .listbox__option {
  &--active {
    @include focus-visible-default-styling;
  }
}

</style>
