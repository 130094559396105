<script setup lang="ts">
import type {ThinkingTrap} from '@/ts/types/dto/interventions-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import SessionTwoBlocks from '@/vue/molecules/components/helpers/session-two-blocks.vue';
import FormRequired from '@/vue/atoms/form-required.vue';
import {computed, getCurrentInstance} from 'vue';
import type {ListBoxOption} from '@/ts/types/component/aria-list-box.type';
import AriaListBox from '@/vue/molecules/aria-list-box.vue';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

const props = defineProps<{
  descriptionHtml?: string;
  traps: ThinkingTrap[];
}>();

const model = defineModel<UuidDTO[]>();

const modelValue = computed({
  get():string[] {
    return model.value as string[];
  },
  set(val: string[]): void {
    model.value = val as UuidDTO[];
  },
});

const options = computed((): ListBoxOption[] => {
  return props.traps.map((trap: ThinkingTrap): ListBoxOption => {
    return {
      id: trap.id,
      text: trap.name,
    };
  });
});

const instance = getCurrentInstance();
const listLabelById = computed(() => {
  return `thinking-traps-listbox-label-${instance?.uid}`;
});

</script>
<template>
  <session-two-blocks :name="$t('thinking_traps')" :name-id="listLabelById" :description-html="descriptionHtml">
    <div class="list-container">
      <aria-list-box
        v-model="modelValue"
        :options="options"
        :aria-labelledby="listLabelById"
        orientation="ambiguous"
        class="listbox"
      >
        <template #item="templateProps">
          <div class="list-item">
            <svg-illustration name="sign-up/asterisk-02.svg"/>
            <span>{{ templateProps.option.text }}</span>
          </div>
        </template>
      </aria-list-box>

      <form-required id="thinking-traps" :valid="model!.length > 0" :message="$t('select_one_option')"/>
    </div>
  </session-two-blocks>
</template>
<style lang="scss" scoped>
@mixin thinking-traps-list-narrow {
  @container thinking-traps-list (max-width: 450px) {
    @content;
  }
}

.list-container {
  container-type: inline-size;
  container-name: thinking-traps-list;
}

.list-item {
  display: flex;
  gap: var(--Spacing-spacing-1);
}

.listbox {
  display: flex;
  flex-wrap: wrap;
  gap: var(--Spacing-spacing-1);

  &:deep(.listbox__option) {
    background-color: var(--Neutral-UI-neutral-ui-10);
    padding: var(--Spacing-spacing-1) var(--Spacing-spacing-2);
    border-radius: 21px;
    cursor: pointer;
    @include focus-visible;
  }

  &:deep(.listbox__option--selected) {
    background-color: var(--Secondary-secondary-color-2);
  }

  @include thinking-traps-list-narrow {
    flex-direction: column;
  }
}
</style>
