<script setup lang="ts">
import type {Journal} from '@/ts/types/dto/interventions-dto';
import {
  AnswerComponentType,
  type SessionRequestJournal,
} from '@/ts/types/dto/session-request-dto';
import {
  computed,
  onBeforeMount,
  watch,
} from 'vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import ExampleSingleInput from '@/vue/molecules/components/helpers/example-single-input.vue';
import type {ComponentTheme} from '@/ts/types/component/layout-component-types';

const props = defineProps<{
  journal: Journal;
  theme: ComponentTheme;
}>();


const logger = useLogger();
const model = defineModel<SessionRequestJournal[]>();

function setValue(newValue: string): void {
  model.value = [
    {
      type: AnswerComponentType.JOURNAL,
      answerJournal: {
        userComponentId: props.journal.userComponentId,
        answer: {
          answer: newValue,
        },
      },
    },
  ];
}

watch(() => props.journal.answer, (newValue) => {
  logger.debug(`watch ${props.journal.contentId} ${newValue}`)();
  setValue(newValue ?? '');
});

onBeforeMount(() => {
  logger.debug(`onBeforeMount ${props.journal.contentId} ${props.journal.answer}`)();
  setValue(props.journal.answer ?? '');
});

const value = computed({
  get: () => model.value ? model.value[0].answerJournal.answer.answer : '',
  set: (newValue: string) => {
    setValue(newValue);
  },
});

</script>
<template>
  <example-single-input
    :id="`journal-${props.journal.contentId}`"
    v-model="value"
    :theme="theme"
    :name="journal.name"
    :description-html="journal.prompt"
    :example-html="journal.examples"
  />
</template>

