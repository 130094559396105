import {pagesPath} from '@/ts/router/pages-path';
import type {RouteRecordRaw} from 'vue-router';
import MainBasePage from '@/vue/pages/main-base-page.vue';
import {interventionPages} from '@/ts/router/pages/intervention-pages';
import InterventionsListPage from '@/vue/pages/interventions/interventions-list-page.vue';
import InterventionFetchPage from '@/vue/pages/interventions/intervention-fetch-page.vue';
import {sessionPages} from '@/ts/router/pages/session-pages';
import ChangePasswordPage from '@/vue/pages/user/change-password-page.vue';

export const mainPages: RouteRecordRaw = {
  path: pagesPath.main.home,
  component: MainBasePage,
  redirect: pagesPath.intervention.interventionsList,
  meta: {
    auth: true,
  },
  children: [
    // We temporary use the interventions page as home
    // {
    //   path: pagesPath.main.home,
    //   component: HomeLandingPage,
    // },
    {
      path: pagesPath.account.changePassword,
      component: ChangePasswordPage,
    },
    {
      path: pagesPath.intervention.interventionsList,
      component: InterventionsListPage,
      children: [
        {
          path: pagesPath.intervention.interventionFetch,
          components: {
            default: InterventionsListPage,
            modal: InterventionFetchPage,
          },
          children: [
            ...interventionPages,
          ],
        },
      ],
    },
    ...sessionPages,
  ],
};
