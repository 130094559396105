<script setup lang="ts">
/* eslint-disable max-lines */
import {type TableDTO, type TableCellDTO, TableDisplayTypeDTO} from '@/ts/types/dto/interventions-dto';
import {computed, ref, watchEffect} from 'vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {ComponentTheme} from '@/ts/types/component/layout-component-types';
import type {Cell} from '@/ts/types/component/table-component.type';

const props = defineProps<{
  table: TableDTO;
  theme: ComponentTheme;
}>();

const {warn} = useLogger();

const firstRowIsHeader = computed((): boolean => {
  return props.table.displayType === TableDisplayTypeDTO.HEADER
      // legacy, could be that the CMS still delivers the old type-name (we renamed it)
      // TODO: remove the following line
      || (props.table.displayType as string) === 'ROW_HEADER_COLUMN_BORDERS';
});

const tableSize = computed((): {rows: number, columnsPerRow: number} => {
  return props.table.cells.reduce((previousValue, currentValue: TableCellDTO) => {
    return {
      rows: Math.max(previousValue.rows, currentValue.y + 1),
      columnsPerRow: Math.max(previousValue.columnsPerRow, (currentValue.x + 1)),
    };
  }, {rows: 1, columnsPerRow: 1});
});

const orderedCells = computed((): Cell[] => {
  const cells: Cell[] = [];
  for (let i = 0, len = tableSize.value.rows * tableSize.value.columnsPerRow; i < len; i++) {
    const x = i % tableSize.value.columnsPerRow;
    const y = Math.floor(i / tableSize.value.columnsPerRow);

    const cell = props.table.cells.find((cellItem) => {
      return cellItem.x === x && cellItem.y === y;
    });

    const isHeaderRow = firstRowIsHeader.value && i < tableSize.value.columnsPerRow;
    const isFirstInRow = (i + 1) % tableSize.value.columnsPerRow === 1;
    const isLastInRow = (i + 1) % tableSize.value.columnsPerRow === 0;

    if (cell) {
      const {id: _, ...rest} = cell;
      cells.push({
        ...rest,
        isHeaderRow,
        isFirstInRow,
        isLastInRow,
        isEmpty: !cell.text,
      });
    } else {
      warn(
        `Incomplete table structure, missing ${y}-${x} cell. Filled up with empty cell.`,
      )();
      cells.push({
        x,
        y,
        text: '',
        isHeaderRow,
        isFirstInRow,
        isLastInRow,
        isEmpty: true,
      });
    }
  }

  return cells;
});

const tableEl = ref<HTMLElement>();

watchEffect(() => {
  if (tableEl.value) {
    // set number of columns per row as scoped CSS variable
    tableEl.value?.style.setProperty('--columns-desktop', tableSize.value.columnsPerRow.toString());
  }
});

</script>
<template>
  <div class="table-component" :class="{'table-component--card': theme === ComponentTheme.CARD}">
    <div
      ref="tableEl"
      class="table"
      :class="{'table--two-columns-no-header': !firstRowIsHeader && tableSize.columnsPerRow === 2}"
    >
      <div
        v-for="(cell, index) in orderedCells"
        :key="`${cell.y}-${cell.x}`"
        class="cell contains-cms-markup"
        :class="{
          'cell--header-row': cell.isHeaderRow,
          'cell--first-in-row': cell.isFirstInRow,
          'cell--last-in-row': cell.isLastInRow,
          'cell--empty': cell.isEmpty,
        }"
        :aria-hidden="cell.isHeaderRow"
      >
        <strong
          v-if="firstRowIsHeader && !cell.isHeaderRow && cell.text"
          class="cell-headline-mobile-and-a11y"
          v-html="orderedCells[(index % tableSize.columnsPerRow)].text"
        />
        <span v-if="cell.text" v-html="cell.text"/>
        <div
          v-if="!cell.isHeaderRow && cell.isFirstInRow"
          class="arrow"
          aria-hidden="true"
        >
          <svg-illustration name="arrow-narrow-right.svg"/>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@mixin table-component-narrow {
  @container table-component (max-width: 699px) {
    @content;
  }
}

@mixin table-component-broad {
  @container table-component (min-width: 700px) {
    @content;
  }
}

.table-component {
  container-type: inline-size;
  container-name: table-component;

  &--card {
    @include q-card-white;
    padding: var(--Spacing-spacing-4);
    @include screen-md {
      padding: var(--Spacing-spacing-7);
    }
  }
}

.table {
  --columns-desktop: 1; // will be overridden by JS
  display: grid;
  grid-template-columns: 100%;

  @include table-component-broad {
    grid-template-columns: repeat(var(--columns-desktop), 1fr);
  }

  &--two-columns-no-header {
    @include table-component-broad {
      grid-template-columns: 1fr 2fr
    }
  }
}

.cell {
  position: relative;
  padding: var(--Spacing-spacing-1) 0;

  @include table-component-broad {
    padding: var(--Spacing-spacing-4);
    border-bottom: 2px solid var(--Neutral-UI-neutral-ui-10);
  }

  &--first-in-row {
    @include table-component-broad {
      @include body-medium-3;
      padding-left: 0;
    }
    @include screen-2xl {
      padding-right: var(--Spacing-spacing-7); // more space for arrow
    }
  }

  &--last-in-row {
    @include table-component-narrow {
      border-bottom: 2px solid var(--Neutral-UI-neutral-ui-10);
      padding-bottom: var(--Spacing-spacing-4);
      margin-bottom: var(--Spacing-spacing-4);
    }
    @include table-component-broad {
      padding-right: 0;
      margin-bottom: 0;
    }
  }

  &--empty {
    @include table-component-narrow {
      padding-bottom: 0;
    }
  }

  &--header-row {
    display: none;
    @include table-component-broad {
      display: block;
      @include body-medium-3;
    }
  }
}

.cell-headline-mobile-and-a11y {
  @include table-component-narrow {
    @include body-medium-3;
    color: var(--Neutral-UI-neutral-ui-70);
    display: block;
  }
  @include table-component-broad {
    @include sr-only;
  }
}

.contains-cms-markup {
  :deep(ul) {
    padding: 0 0 0 var(--Spacing-spacing-3);
    margin: 0;
  }
  :deep(p:last-child) {
    margin-bottom: 0;
  }
}

.arrow {
  display: none;
  @include screen-2xl {
    display: flex;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    align-items: center;
    color: var(--Neutral-UI-neutral-ui-40);
  }
}
</style>
