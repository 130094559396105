<script setup lang="ts">
/* eslint-disable  max-lines */
// TODO iterate over the solutions with an unique id
import ExampleBlock from '@/vue/molecules/components/helpers/example-block.vue';
import type {Solution} from '@/ts/types/dto/interventions-dto';
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';
import BalmBtn from '@/vue/atoms/balm-btn.vue';

defineProps<{
  exampleHtml: string;
  descriptionHtml: string;
}>();

const model = defineModel<Solution[]>();

defineEmits<{
  add: [];
  delete: [number];
}>();

</script>
<template>
  <div class="possible-solutions">
    <div class="instructions">
      <h5 class="title">
        {{ $t('possible_solutions') }}
      </h5>
      <div v-if="descriptionHtml" class="description" v-html="descriptionHtml"/>
    </div>
    <example-block v-if="exampleHtml" class="example" :name="$t('solution')" :example-html="exampleHtml"/>
    <div class="solutions">
      <div v-for="(key, i) in model" :key="i" class="solution">
        <div class="solution-name">
          <div class="label">
            {{ $t('solution') }}
          </div>
          <q-input
            v-model="model![i].name"
            class="input"
            type="textarea"
          />
          <flat-icon-btn icon="trash.svg" class="trash-icon" :label="$t('delete')" @click="$emit('delete', i)"/>
        </div>
        <div class="good-things">
          <div class="label">
            {{ $t('good_things_about') }}
          </div>
          <q-input
            v-model="model![i].goodThings"
            class="input"
            type="textarea"
          />
        </div>
        <div class="bad-things">
          <div class="label">
            {{ $t('bad_things_about') }}
          </div>
          <q-input
            v-model="model![i].badThings"
            class="input"
            type="textarea"
          />
        </div>
      </div>
    </div>
    <balm-btn
      theme="grey"
      :label="$t('add_item')"
      class="btn"
      icon="plus.svg"
      @click="$emit('add')"
    />
  </div>
</template>
<style scoped lang="scss">
.possible-solutions {
  @include q-card-white;
  padding: var(--Spacing-spacing-4);
  display: grid;
  gap: var(--Spacing-spacing-4);

  @include screen-lg {
    padding: var(--Spacing-spacing-7);
    grid-template-columns: 1fr 2fr;
  }
}

.instructions {
  @include screen-lg {
    grid-column: 1;
    grid-row: 1;
  }
}

.title {
  color: var(--Neutral-UI-neutral-ui-100);
  margin-bottom: var(--Spacing-spacing-4);
}

.example {
  @include screen-lg {
    grid-column: 2;
    grid-row: span 2;
    height: fit-content;
  }
}

.solutions {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);

  @include screen-lg {
    grid-column: span 2;
    grid-row: 3;
  }
}

.solution {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--Spacing-spacing-4);

  @include screen-lg {
    grid-template-columns: repeat(3, 1fr);

    &:not(:first-child) {
      .label {
        display: none;
      }
    }
  }

  &:first-child {
    .trash-icon {
      display: none;
    }
  }
}

.input {
  background-color: var(--color-white);

  :deep(textarea) {
    padding: var(--Spacing-spacing-4);

    @include screen-md {
      padding: var(--Spacing-spacing-6);
    }
  }
}

.solution-name {
  position: relative;
}

.trash-icon {
  position: absolute;
  right: var(--Spacing-spacing-2);
  bottom: var(--Spacing-spacing-2);
}

.label {
  @include body-medium-3;
  margin-bottom: var(--Spacing-spacing-2);
}

.btn {
  margin-left: auto;
  
  @include screen-lg {
    grid-column: 2;
  }
}
</style>
