<script lang="ts" setup>
import ReadOnlyView from '@/vue/molecules/read-only/read-only-view.vue';
import {computed, onMounted, ref} from 'vue';
import type {QuasarOption} from '@/ts/types/component/select-multichoice';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {type Session, SessionState, type Intervention} from '@/ts/types/dto/interventions-dto';

const props = defineProps<{
  intervention: Intervention;
}>();

const completedSessions = computed<Session[]>(() => props.intervention.sessions
  .filter(sess => sess.state === SessionState.COMPLETED && sess.completedDate));

const options = computed<QuasarOption<UuidDTO>[]>(() => completedSessions.value.map(sess => ({label: sess.name, value: sess.id})));

const selectedOption = ref<QuasarOption<UuidDTO> | null>(null);
const selectedSession = computed(() => selectedOption.value 
  ? completedSessions.value.find(sess => sess.id === selectedOption.value!.value) : null);

onMounted(() => {
  if (completedSessions.value?.[0]) {
    [selectedOption.value] = options.value;
  }
});
</script>
<template>
  <div class="recap">
    <div v-if="completedSessions.length === 0">
      {{ $t('no_current_sessions') }}
    </div>
    <q-select
      v-else
      v-model="selectedOption"
      :options="options"
      :label="$t('session')"
    />
    <read-only-view
      v-if="selectedSession"
      :session="selectedSession"
      :primary-feelings="intervention!.primaryFeelings"
      :thinking-traps="intervention!.thinkingTraps"
    />
  </div>
</template>
<style lang="scss" scoped>
.recap {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-3);

  @include screen-md {
    gap: var(--Spacing-spacing-7);
  }
}
</style>
