<script setup lang="ts">
import type {Questionnaire} from '@/ts/types/dto/interventions-dto';
import {
  onBeforeMount,
  ref,
} from 'vue';
import type {SessionRequestQuestionnaire} from '@/ts/types/dto/session-request-dto';
import {useLogger} from '@/ts/composables/pure/use-logger';
import ContentBlock from '@/vue/molecules/components/helpers/content-block.vue';
import QuestionnaireResultBlock from '@/vue/molecules/components/questionnaire/questionnaire-result-block.vue';
import QuestionnaireStandardModal from '@/vue/molecules/components/questionnaire/questionnaire-standard-modal.vue';
import type {ComponentTheme} from '@/ts/types/component/layout-component-types';

const props = defineProps<{
  questionnaire: Questionnaire;
  loading: boolean;
  theme: ComponentTheme;
}>();

defineEmits<{
  update: []
}>();


// TODO implement hideContinue when needed, right now not used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hideContinue = defineModel<boolean>('hideContinue');
const model = defineModel<SessionRequestQuestionnaire[] | null>();

const logger = useLogger();
const isModalOpen = ref(false);

onBeforeMount(() => {
  logger.debug(`onBeforeMount ${props.questionnaire.id}`)();
  if (!props.questionnaire.isAnswered) {
    // TODO this hideContinue functionality is broken if multiple questionnaire standard
    // are present on the page, the last one is not open, but you open the previous one
    // and click on finish
    // hideContinue.value = true;
  }
});

function finish(): void {
  // TODO
  // hideContinue.value = false;
  isModalOpen.value = false;
}

</script>
<template>
  <div class="questionnaire-standard">
    <content-block
      :content="questionnaire.description"
      :image="questionnaire.image"
      :name="questionnaire.name"
      :theme="theme"
    />
    <questionnaire-result-block :questionnaire="questionnaire" @open="isModalOpen = true"/>
    <questionnaire-standard-modal
      v-model="model"
      v-model:is-modal-open="isModalOpen"
      :questionnaire="questionnaire"
      :loading="loading"
      @finish="finish"
      @update="$emit('update')"
    />
  </div>
</template>
<style scoped lang="scss">
.questionnaire-standard {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);

  @include screen-md {
    gap: var(--Spacing-spacing-7);
  }
}
</style>
