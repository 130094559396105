import type {
  Component,
  ILayoutComponent,
} from '@/ts/types/dto/interventions-dto';

export function getId(component: Component): string {
  const res = component.questionnaire?.id
    ?? component.journal?.userComponentId
    ?? component.table?.id
    ?? component.conceptualizationWorksheet?.userComponentId
    ?? component.problemSolvingWorksheet?.userComponentId
    ?? component.content?.id
    ?? component.questionnaireReview?.id
    ?? component.componentLayout?.contentId;
  if (!res) {
    throw new Error('Component has no id');
  }
  return res;
}

export function getLayoutId(component: ILayoutComponent): string {
  const res = component.questionnaire?.id
    ?? component.journal?.userComponentId
    ?? component.table?.id
    ?? component.content?.id
    ?? component.questionnaireReview?.id
    ?? component.goal?.id;
  if (!res) {
    throw new Error('Component has no id');
  }
  return res;
}
