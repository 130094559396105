<script setup lang="ts">
import type {Layout} from '@/ts/types/dto/interventions-dto';
import type {SessionRequest} from '@/ts/types/dto/session-request-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import LayoutComponent from '@/vue/molecules/components/layout-component.vue';
import {
  reactive,
  watch,
} from 'vue';
import {useLogger} from '@/ts/composables/pure/use-logger';

// THIS component is highly cohesive with section-component and layout-component
const logger = useLogger();
const props = defineProps<{
  layout: Layout;
  iid: UuidDTO;
  sid: UuidDTO;
  loading: boolean;
}>();

defineEmits<{
  update: [];
  finish: [];
}>();

const hideContinue = defineModel<boolean>('hideContinue');
const model = defineModel<SessionRequest[]>();

const reactiveObject = reactive<Record<string, SessionRequest[]|undefined>>(
  // eslint-disable-next-line no-undefined
  Object.fromEntries(props.layout.componentsLayout.map((val, index) => [index, undefined])),
);

watch(reactiveObject, (newModelValue) => {
  logger.debug('watch model {}', newModelValue)();
  // first filter out models that were not initialized from the child
  model.value = Object.values(newModelValue)
    .filter(ss => ss) // first filter out models that were not initialized from the child
    .flat().filter(ss => ss) as SessionRequest[]; // remove nesting from layout of array of array and leave only values for parent that has inputs
}, {deep: true});


</script>
<template>
  <div class="layout-components" :class="`layout-component-${layout.displayType}`">
    <layout-component
      v-for="(component, index) in layout.componentsLayout"
      :key="`${layout}-${index}`"
      v-model="reactiveObject[index]"
      :component="component"
      :iid="iid"
      :sid="sid"
      :index="index"
      :loading="loading"
      :hide-continue="hideContinue"
      class="layout-component-child"
    />
  </div>
</template>
<style lang="scss" scoped>
.layout-components {
  @include q-card-0;
  gap: var(--Spacing-spacing-6);
  padding: var(--Spacing-spacing-4);
  @include screen-lg {
    padding: var(--Spacing-spacing-7);
  }

  display: flex;
  gap: var(--Spacing-spacing-4);
  container-type: inline-size;
}

.layout-component-row {
  flex-direction: row;
  flex-wrap: wrap;

}

.layout-component-column {
  flex-direction: column;
}
</style>
