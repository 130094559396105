<script lang="ts" setup>
/* eslint-disable max-lines */
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';
import {computed, ref} from 'vue';
import {useFocusTrap} from '@/ts/composables/stateful/use-focus-trap';

const props = defineProps<{
  isOpen: boolean,
  alignRight?: boolean,
}>();

const emit = defineEmits<{
  swipeOrEscToClose: []
}>();

const {isMobileResolution} = useScreenSize();

const isFocusTrapEnabled = computed(() => {
  return isMobileResolution.value && props.isOpen;
});

const drawer = ref<HTMLElement>();

useFocusTrap(drawer, isFocusTrapEnabled);

function onSwipe(event: any): void { // eslint-disable-line @typescript-eslint/no-unsafe-member-access
  if (
      (props.alignRight && event.direction === 'right') || // eslint-disable-line @typescript-eslint/no-unsafe-member-access
      (!props.alignRight && event.direction === 'left')) // eslint-disable-line @typescript-eslint/no-unsafe-member-access
  {
    emit('swipeOrEscToClose');
  }
}

function onClickOutside(): void {
  if (drawer.value) {
    const drawerBoundingClientRect = drawer.value.getBoundingClientRect();
    const documentBoundingClientRect = document.body.getBoundingClientRect();
    const isDrawerPulledOut = props.alignRight ?
      drawerBoundingClientRect.right <= documentBoundingClientRect.width :
      drawerBoundingClientRect.left >= 0;

    if (isMobileResolution.value && isDrawerPulledOut) {
      emit('swipeOrEscToClose');
    }
  }
}

</script>

<template>
  <div
    ref="drawer"
    v-touch-swipe.mouseCapture.horizontal="onSwipe"
    :class="{
      'drawer-on-mobile': isMobileResolution,
      'drawer-on-mobile--align-right': alignRight,
      'drawer-on-mobile--align-left': !alignRight,
      'drawer-on-mobile--open': (isMobileResolution && isOpen),
    }"
    :role="isMobileResolution ? 'dialog' : undefined"
    :aria-modal="isMobileResolution ? 'true' : undefined"
    :inert="isMobileResolution ? !isOpen : undefined"
    @keydown.esc="emit('swipeOrEscToClose')"
  >
    <div v-click-outside="onClickOutside" class="drawer-content">
      <slot/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$drawerOnMobileWidth: 320px;

.drawer-on-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  width: $drawerOnMobileWidth;
  z-index: 5;

  &:before {
    opacity: 0;
    content: '';
    transition: opacity .2s;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--Neutral-UI-neutral-ui-10);
  }

  &--open {
    &:before {
      content: "";
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 1;
    }
  }

  &--align-left {
    left: -$drawerOnMobileWidth;
    transition: left .2s ease-out;

    &.drawer-on-mobile--open {
      left: 0px;
    }

    &:after {
      border-radius: 0 24px 24px 0;
    }
  }

  &--align-right {
    right: -$drawerOnMobileWidth;
    transition: right .2s ease-out;

    &.drawer-on-mobile--open {
      right: 0px;
    }

    &:after {
      border-radius: 24px 0 0 24px;
    }
  }

  @include screen-lg {
    position: static;
    top: auto;
    left: auto;
    bottom: auto;
    width: auto;
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
}

.drawer-content {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
