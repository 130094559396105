<script setup lang="ts" generic="T">
import type {SideBarSectionItem} from '@/ts/types/component/balm-sidebar';

defineProps<{
  items: SideBarSectionItem[]
  name: string;
}>();
</script>
<template>
  <div class="section">
    <div class="section-category">
      {{ name }}
    </div>
    <q-expansion-item
      v-for="item in items"
      :key="item.id"
      class="section-item"
      :label="item.label"
      header-class="section-question"
    >
      <div class="section-answer" v-html="item.html"/>
    </q-expansion-item>
  </div>
</template>
<style scoped lang="scss">
.section {
  margin: var(--Spacing-spacing-6) 0;
}


.section-category {
  padding-bottom: var(--Spacing-spacing-3);
  @include body-medium-3;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.section-item {
  margin-bottom: var(--Spacing-spacing-2);
  border-radius: var(--card-border-radius);
  background: var(--Neutral-UI-neutral-ui-20);

  :deep(.q-item) {
    padding: var(--Spacing-spacing-3);
    min-height: 0;

    @include screen-md {
      padding: var(--Spacing-spacing-4) var(--Spacing-spacing-5);
    }
  }

  :deep(.section-question) {
    @include body-small-3;
  }

  &.q-expansion-item--expanded {
    background-color: var(--Secondary-secondary-color-1);
    transition: background-color .3s;
  }
}

.section-answer {
  padding: var(--Spacing-spacing-3);
  @include body-small-2;

  @include screen-md {
    padding: var(--Spacing-spacing-4) var(--Spacing-spacing-5);
  }
}
</style>
