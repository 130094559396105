<script setup lang="ts">
/* eslint-disable max-lines */
import {
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import ThinkingTrapsInput from '@/vue/molecules/components/helpers/thinking-traps-input.vue';
import FeelingsBlock from '@/vue/molecules/components/helpers/feelings-block.vue';
import type {SessionRequestConceptualizationWorksheet} from '@/ts/types/dto/session-request-dto';
import ExampleSingleInput from '@/vue/molecules/components/helpers/example-single-input.vue';
import ExampleMultipleInput from '@/vue/molecules/components/helpers/example-multiple-input.vue';
import type {
  ConceptualizationComponentProps,
  ConceptualizationComponentValue,
} from '@/ts/types/component/conceptualization-component';
import {
  getDefaultQuestionnaireAnswers,
  getQuestionnaireAnswers,
} from '@/ts/composables/pure/use-conceptualization-mapper';
import {useLogger} from '@/ts/composables/pure/use-logger';
import QuestionnaireComponent from '@/vue/molecules/components/questionnaire/questionnaire-component.vue';
import {ComponentTheme} from '@/ts/types/component/layout-component-types';

const props = defineProps<ConceptualizationComponentProps>();
const model = defineModel<SessionRequestConceptualizationWorksheet[]>();
const value = ref<ConceptualizationComponentValue>(null!);
const logger = useLogger();

onBeforeMount(() => {
  logger.debug(`onBeforeMount ${props.worksheet.contentId}`)();
  value.value = getDefaultQuestionnaireAnswers(props);
  if (!model.value) {
    model.value = getQuestionnaireAnswers(value.value, props);
  }
});

watch(
  value,
  (newValue) => {
    logger.debug(`watch ${props.worksheet.contentId}`)();
    model.value = getQuestionnaireAnswers(newValue, props);
  },
  {deep: true},
);
</script>
<template>
  <div class="wrapper">
    <example-single-input
      v-if="worksheet.situation.show"
      :id="`concept-${props.worksheet.contentId}-situation`"
      v-model="value.situation"
      :name="$t('situation')"
      :description-html="worksheet.situation.description"
      :example-html="worksheet.situation.example"
      :theme="ComponentTheme.CARD"
    />
    <questionnaire-component
      v-if="props.worksheet.questionnaire"
      v-model="value.questionnaire"
      :loading="loading"
      :questionnaire="props.worksheet.questionnaire"
      :theme="ComponentTheme.CARD"
    />
    <example-multiple-input
      v-if="worksheet.thoughts.show"
      v-model="value.thoughts"
      :name="$t('thoughts')"
      :description-html="worksheet.thoughts.description"
      :example-html="worksheet.thoughts.example"
    />
    <feelings-block
      v-if="worksheet.primaryFeelingsIds.show"
      v-model:primary="value.primaryFeelingsIds"
      v-model:secondary="value.secondaryFeelingsIds"
      :feelings="primaryFeelings"
      :description-html="worksheet.primaryFeelingsIds.description"
    />
    <example-multiple-input
      v-if="worksheet.behaviors.show"
      v-model="value.behaviors"
      :name="$t('behaviours')"
      :description-html="worksheet.behaviors.description"
      :example-html="worksheet.behaviors.example"
    />
    <example-multiple-input
      v-if="worksheet.physicalReactions.show"
      v-model="value.physReactions"
      :name="$t('cues')"
      :description-html="worksheet.physicalReactions.description"
      :example-html="worksheet.physicalReactions.example"
    />
    <thinking-traps-input
      v-if="worksheet.thinkingTraps.show"
      v-model="value.thinkingTraps"
      :traps="thinkingTraps"
      :description-html="worksheet.thinkingTraps.description"
    />
    <example-multiple-input
      v-if="worksheet.copingThoughts.show"
      v-model="value.copyingThoughts"
      :name="$t('copying_thoughts')"
      :description-html="worksheet.copingThoughts.description"
      :example-html="worksheet.copingThoughts.example"
    />
  </div>
</template>
<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);
}
</style>
