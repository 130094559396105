<script lang="ts" setup>
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';

const modal = defineModel<boolean>();
defineSlots<{
  default: [];
}>();

withDefaults(defineProps<{
  type?: 'fh' | 'fit-content' | 'h900';
  theme?: 'grey-10' | 'grey-20' | 'neutral-0';
  noX?: boolean;
  noScroll?: boolean;
}>(), {
  type: 'fh',
  theme: 'grey-20',
  noX: false,
  noScroll: false,
});

const {isMobileResolution} = useScreenSize();

</script>
<template>
  <q-dialog
    v-model="modal"
    class="balm-dialog"
    :persistent="!noX"
    :transition-show="isMobileResolution ? 'slide-up' : 'jump-up'"
    :transition-hide="isMobileResolution ? 'slide-down' : 'jump-down'"
    :position="isMobileResolution ? 'bottom' : 'standard'"
  >
    <div class="modal-card" :class="[type, theme,]">
      <slot v-if="noScroll"/>
      <div v-else class="modal-scroll">
        <slot/>
      </div>
      
      <div class="x-close-wrapper">
        <flat-icon-btn
          v-if="!noX"
          class="x-close"
          icon="x-close.svg"
          :label="$t('aria_close_modal')"
          @click="modal = false"
        />
      </div>
    </div>
  </q-dialog>
</template>
<style lang="scss" scoped>
.x-close-wrapper {
  position: absolute;
  right: var(--Spacing-spacing-4);
  top: var(--Spacing-spacing-4);
  background-color: inherit;
  border-radius: 50%;
}

.modal-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: var(--Spacing-spacing-4);
  min-height: 100%;
}

/* eslint-disable-next-line vue-scoped-css/no-unused-selector */
.modal-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1500px;
  height: calc(100vh - var(--header-height));
  overflow: hidden;
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);

  &.grey-10 {
    background-color: var(--Neutral-UI-neutral-ui-10);
  }

  &.grey-20 {
    background-color: var(--Neutral-UI-neutral-ui-20);
  }

  &.neutral-0 {
    background-color: var(--Neutral-UI-neutral-ui-0);
  }

  @include screen-lg {

    border-bottom-left-radius: var(--card-border-radius);
    border-bottom-right-radius: var(--card-border-radius);

    &.fit-content {
      width: fit-content;
      height: fit-content;
    }

    &.fh {
      height: 100%;
    }

    &.h900 {
      height: 100%;
      max-height: 900px;
    }
  }
}
</style>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
// unscoped styles !!!
// override quasar behaviour
// deep doesn't work because of the portal
// all styles hear should be wrapped in the .balm-dialog class

.balm-dialog {
  .q-dialog__inner{
    &--minimized {
      padding: 0;
      @include screen-lg {
        padding: var(--Spacing-spacing-7);
      }
    }
    & > div {
      max-height: none !important;
      @include screen-lg {
        max-height: calc(100vh - 100px) !important;
      }
    }
  }
}

</style>
