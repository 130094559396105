<script lang="ts" setup>

defineProps<{
  active: boolean;
}>();

defineSlots<{
  default?: any;
}>();
</script>

<template>
  <div class="read-only-non-collapsable" :class="{'user-answer': active, info: !active}">
    <slot/>
  </div>
</template>
<style lang="scss" scoped>
.read-only-non-collapsable {
  border-radius: var(--card-border-radius);
  background: var(--Neutral-UI-neutral-ui-10);
  padding: var(--Spacing-spacing-3);

  @include screen-md {
    padding: var(--Spacing-spacing-5) var(--Spacing-spacing-7);
  }

  &.info {
    border-left: 6px solid var(--Neutral-UI-neutral-ui-30);
  }

  &.user-answer {
    border-left: 6px solid var(--Primary-primary-color-2);
  }
}
</style>
