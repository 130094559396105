<script setup lang="ts">
import {ref} from 'vue';
import type {GenderDTO} from '@/ts/types/dto/gender-dto';
import {
  useEmailRule,
  useRequiredNumber,
  useRequiredRule,
  useYearsOfBirth,
} from '@/ts/composables/pure/use-input-rules';
import type {
  CountryCode,
  EducationLevelDTO,
  UuidDTO,
} from '@/ts/types/dto/sign-in-dto';
import {useApi} from '@/ts/composables/stateful/use-api';
import {SIGN_UP_FORM_DEFAULT_VALUES} from '@/ts/utils/consts';
import GenderSelector from '@/vue/atoms/gender-selector.vue';
import CountrySelector from '@/vue/atoms/country-selector.vue';
import EducationSelector from '@/vue/atoms/education-selector.vue';
import {useLoadingSpinner} from '@/ts/composables/pure/use-loading-notification';
import {QForm} from 'quasar';
import {useRecaptcha} from '@/ts/composables/stateful/use-recaptcha';
import {useLang} from '@/ts/composables/stateful/use-lang';

const props = defineProps<{
  termsAndConditionsId: UuidDTO;
  privacyPolicyId: UuidDTO;
  marketingCodeParam?: string;
}>();

const signUpForm = ref<QForm>(null!);

const emailRule = useEmailRule();
const requiredNumberRule = useRequiredNumber();
const requiredRule = useRequiredRule();

const [minYear, maxYear] = useYearsOfBirth();

const email = ref<string>(SIGN_UP_FORM_DEFAULT_VALUES?.email ?? '');
const name = ref<string>(SIGN_UP_FORM_DEFAULT_VALUES?.name ?? '');
const gender = ref<{
  value: GenderDTO;
  label: string;
} | null>(SIGN_UP_FORM_DEFAULT_VALUES?.gender ?? null);
const country = ref<CountryCode | null>((SIGN_UP_FORM_DEFAULT_VALUES?.country as CountryCode) ?? null);
const birthYear = ref<number | null>(SIGN_UP_FORM_DEFAULT_VALUES?.birthYear ?? null);
// defining type for education is impossible cause of inner reference from .d.ts to the project
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const education = ref<EducationLevelDTO | null>(SIGN_UP_FORM_DEFAULT_VALUES?.education ?? null);
const marketingCode = ref<string>(props.marketingCodeParam ?? SIGN_UP_FORM_DEFAULT_VALUES?.marketingCode ?? '');

const emit = defineEmits<{
  finish: [string]
}>();

function submitForm(): void {
  signUpForm.value!.submit();
}

defineExpose({
  submitForm,
});

const api = useApi();
const getRecaptchaToken = useRecaptcha();
const {lang} = useLang();
const signUp = useLoadingSpinner(
  async() => api.public.signUp({
    email: email.value,
    birthYear: birthYear.value!,
    countryCode: country.value!,
    // this is taken care by form value
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    gender: gender.value!.value,
    name: name.value,
    termsAndConditionsId: props.termsAndConditionsId,
    privacyPolicyId: props.privacyPolicyId,
    educationLevel: education.value!,
    marketingCode: marketingCode.value,
    recaptchaToken: await getRecaptchaToken(),
    languageCode: lang.value,
  }),
  () => {
    emit('finish', email.value);
  },
);
</script>
<template>
  <q-form ref="signUpForm" @submit="signUp">
    <h1>{{ $t('your_info') }}</h1>
    <q-input v-model="email" :rules="[emailRule]" :label="$t('email')" autocomplete="email"/>
    <q-input v-model="name" :rules="[requiredRule]" :label="$t('your_name')" autocomplete="name"/>
    <country-selector v-model="country"/>
    <q-input
      v-model.number="birthYear"
      type="number"
      :rules="[requiredNumberRule, minYear, maxYear]"
      :label="$t('birthday')"
      autocomplete="bday-year"
    />
    <gender-selector v-model="gender"/>
    <education-selector v-model="education!"/>
    <q-input
      v-model="marketingCode"
      :rules="[requiredRule]"
      :label="$t('code')"
      autocomplete="code"
      :disable="!!marketingCodeParam"
    />
  </q-form>
</template>
<style lang="scss" scoped>
h1 {
  @include heading-1;
  margin-bottom: var(--Spacing-spacing-3);
}
</style>
