<script setup lang="ts">
import {
  type Questionnaire,
  QuestionnaireType,
} from '@/ts/types/dto/interventions-dto';
import type {SessionRequestQuestionnaire} from '@/ts/types/dto/session-request-dto';
import QuestionnaireStandard from '@/vue/molecules/components/questionnaire/questionnaire-standard.vue';
import ErrorBox from '@/vue/molecules/error-box.vue';
import QuestionnaireGridVertical from '@/vue/molecules/components/questionnaire/questionnaire-grid-vertical.vue';
import {
  computed,
} from 'vue';
import type {ComponentTheme} from '@/ts/types/component/layout-component-types';

const props = defineProps<{
  questionnaire: Questionnaire;
  loading: boolean;
  theme: ComponentTheme;
}>();

defineEmits<{
  update: [];
  finish: [];
}>();

const hideContinue = defineModel<boolean>('hideContinue');

const isVerticalGrid = computed(
  () => [QuestionnaireType.SHOW_ONLY_QUESTIONS, QuestionnaireType.GRID_LAYOUT].includes(props.questionnaire.type),
);

const model = defineModel<SessionRequestQuestionnaire[] | null>();
</script>
<template>
  <div :class="`content-${theme}`">
    <questionnaire-standard
      v-if="questionnaire.type === QuestionnaireType.STANDARD"
      v-model="model"
      v-model:hide-continue="hideContinue"
      :loading="loading"
      :theme="theme"
      :questionnaire="questionnaire"
      @update="$emit('update')"
    />
    <questionnaire-grid-vertical
      v-else-if="isVerticalGrid"
      v-model="model"
      :questionnaire="questionnaire"
    />
    <error-box v-else/>
  </div>
</template>
<style scoped lang="scss">
.content-card {
  @include q-card-white;
  @include q-card-big;
}

.content-raw {
  gap: var(--Spacing-spacing-4);
  @include screen-md {
    gap: var(--Spacing-spacing-6);
  }
}
</style>
