<script setup lang="ts">

import {
  type LayoutGoal,
  LayoutGoalDisplayType,
} from '@/ts/types/dto/interventions-dto';
import GoalShort from '@/vue/molecules/goal-short.vue';

defineProps<{
  goal: LayoutGoal;
  order: number;
}>();
</script>
<template>
  <goal-short :goal="goal" :order="order" :finished="goal.displayType === LayoutGoalDisplayType.COMPLETE"/>
</template>
