import type {DirectiveBinding} from 'vue';


type CurrentElement = HTMLElement & {
  keydownListener?(event: KeyboardEvent): void;
};
export const moveSelection = {
  mounted(el: CurrentElement, binding: DirectiveBinding<(direction: 'horizontal' | 'vertical', amount: -1 | 1) => void>): void {
    function listener(event: KeyboardEvent): void {
      const {key} = event;
      event.preventDefault();
      if (key === 'ArrowUp') {
        binding.value('vertical', -1);
      } else if (key === 'ArrowDown') {
        binding.value('vertical', 1);
      } else if (key === 'ArrowLeft') {
        binding.value('horizontal', -1);
      } else if (key === 'ArrowRight') {
        binding.value('horizontal', 1);
      }
    }
    el.addEventListener('keydown', listener);
    el.keydownListener = listener;
  },
  beforeUnmount(el: CurrentElement): void {
    if (el.keydownListener) {
      // directive are functions with bound this, so we can ignore it
      // eslint-disable-next-line @typescript-eslint/unbound-method
      el.removeEventListener('keydown', el.keydownListener);
      delete el.keydownListener;
    }
  },
};
