export enum MainPath {
  home = '/',
  notFound = '/404',
}

export enum PublicPath {
  public = '/public',
  continueAuth = '/public/continue-auth',
  signInRedirect = '/public/sign-in-redirect',
  signUp = '/public/sign-up',
}

export enum AccountPath {
  changePassword = '/change-password',
}

export enum InterventionPath {
  interventionsList = '/paths',
  interventionFetch = '/path-:iid',
  interventionInfo = '/path-:iid/description',
  interventionInfoAbout = '/path-:iid/description/about',
  interventionInfoSessions = '/path-:iid/description/sessions',
  interventionRecap = '/path-:iid/recap',
}

export enum InterventionRouteName {
  interventionInfo = 'interventionInfo',
}

export enum SessionPath {
  sessionWrapper = '/path-:iid/session-:sid',
  sessionSummary = '/path-:iid/session-:sid/summary',
  sessionLeftMenu = '/path-:iid/session-:sid/page-:pid',
  sessionHomeWork = '/path-:iid/session-:sid/homework-:hid/page-:pid/progress',
  sessionComponent = '/path-:iid/session-:sid/page-:pid/progress',
}

export interface PagesPath {
  intervention: typeof InterventionPath;
  session: typeof SessionPath;
  main: typeof MainPath;
  public: typeof PublicPath;
  account: typeof AccountPath;
}


export const pagesPath: PagesPath = Object.freeze({
  main: MainPath,
  intervention: InterventionPath,
  session: SessionPath,
  public: PublicPath,
  account: AccountPath,
});
