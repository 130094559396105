<script setup lang="ts">
import {type ILayoutComponent, LayoutComponentType} from '@/ts/types/dto/interventions-dto';
import ContentComponent from '@/vue/molecules/components/content-component.vue';
import JournalComponent from '@/vue/molecules/components/journal-component.vue';
import type {
  SessionRequest,
  SessionRequestJournal,
  SessionRequestQuestionnaire,
} from '@/ts/types/dto/session-request-dto';
import ErrorBox from '@/vue/molecules/error-box.vue';
import QuestionnaireReviewComponent from '@/vue/molecules/components/questionnaire-review-component.vue';
import TableComponent from '@/vue/molecules/components/table-component.vue';
import QuestionnaireComponent from '@/vue/molecules/components/questionnaire/questionnaire-component.vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import GoalComponent from '@/vue/molecules/components/goal-component.vue';
import {ComponentTheme} from '@/ts/types/component/layout-component-types';

// THIS component is highly cohesive with section-component and layout-components
defineProps<{
  component: ILayoutComponent;
  iid: UuidDTO;
  sid: UuidDTO;
  loading: boolean;
  index: number;
}>();

defineEmits<{
  update: [];
  finish: [];
}>();

const hideContinue = defineModel<boolean>('hideContinue');
const model = defineModel<SessionRequest[]>();

</script>
<template>
  <table-component
    v-if="component.type === LayoutComponentType.TABLE"
    :table="component.table"
    :theme="ComponentTheme.RAW"
  />
  <content-component
    v-else-if="component.type === LayoutComponentType.CONTENT"
    :content="component.content"
    :theme="ComponentTheme.RAW"
  />
  <journal-component
    v-else-if="component.type === LayoutComponentType.JOURNAL"
    v-model="model as SessionRequestJournal[]"
    :journal="component.journal"
    :theme="ComponentTheme.RAW"
  />
  <questionnaire-component
    v-else-if="component.type === LayoutComponentType.QUESTIONNAIRE"
    v-model="model as SessionRequestQuestionnaire[]"
    v-model:hide-continue="hideContinue"
    :questionnaire="component.questionnaire"
    :loading="loading"
    :theme="ComponentTheme.RAW"
    @update="$emit('update')"
  />
  <questionnaire-review-component
    v-else-if="component.type === LayoutComponentType.QUESTIONNAIRE_REVIEW"
    :iid="iid"
    :sid="sid"
    :review="component.questionnaireReview"
    :theme="ComponentTheme.RAW"
  />
  <goal-component
    v-else-if="component.type === LayoutComponentType.GOAL"
    :goal="component.goal"
    :order="index + 1"
  />
  <error-box v-else/>
</template>

