import {
  computed,
  type Ref,
} from 'vue';
import {
  BalmSidebarType,
  type BalmSidebarMenuItem,
} from '@/ts/types/component/balm-sidebar';
import {useUserStore} from '@/ts/store/user-store';
import {storeToRefs} from 'pinia';


export function useDefaultSideBarMenu(): BalmSidebarMenuItem[] {
  return [
    {
      type: BalmSidebarType.RESOURCES,
      icon: 'layout-grid-01.svg',
      labelKey: 'resources',
    },
    {
      type: BalmSidebarType.FAQ,
      icon: 'message-chat-square.svg',
      labelKey: 'faq',
    },
    {
      type: BalmSidebarType.GLOSSARY,
      icon: 'book-open.svg',
      labelKey: 'glossary',
    },
    {
      type: BalmSidebarType.IMPRINT,
      icon: 'file-shield.svg',
      labelKey: 'imprint',
    },
  ];
}

export function useUserSidebarMenu(): { balmSidebarMenuItems: Ref<BalmSidebarMenuItem[]> } {
  const userStore = useUserStore();
  const {homeContent} = storeToRefs(userStore);
  const homeScreenSideBar = useDefaultSideBarMenu();

  const balmSidebarMenuItems = computed<BalmSidebarMenuItem[]>(() => {
    return homeContent.value!.currentIntervention ? [
      {
        type: BalmSidebarType.SESSION_RECAP,
        icon: 'list.svg',
        labelKey: 'session_recaps',
      },
      ...homeScreenSideBar,
    ] : homeScreenSideBar;
  });

  return {
    balmSidebarMenuItems,
  };
}

