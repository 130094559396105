<script lang="ts" setup>
import {type Layout, LayoutComponentType} from '@/ts/types/dto/interventions-dto';
import ReadOnlyTable from '@/vue/molecules/read-only/read-only-table.vue';
import ReadOnlyJournal from '@/vue/molecules/read-only/read-only-journal.vue';
import ReadOnlyQuestionnaire from '@/vue/molecules/read-only/questionnaire/read-only-questionnaire.vue';
import ErrorBox from '@/vue/molecules/error-box.vue';
import ReadOnlyContent from '@/vue/molecules/read-only/read-only-content.vue';
import {getLayoutId} from '@/ts/composables/pure/use-get-id';

defineProps<{
  layout: Layout;
}>();
</script>

<template>
  <template v-for="component in layout.componentsLayout" :key="getLayoutId(component)">
    <read-only-content
      v-if="component.type === LayoutComponentType.CONTENT"
      :content="component.content"
    />
    <read-only-journal
      v-else-if="component.type === LayoutComponentType.JOURNAL"
      :journal="component.journal"
    />
    <read-only-table
      v-else-if="component.type === LayoutComponentType.TABLE"
      :table="component.table"
    />
    <read-only-questionnaire
      v-else-if="component.type === LayoutComponentType.QUESTIONNAIRE"
      :questionnaire="component.questionnaire"
    />
    <read-only-content
      v-else-if="component.type === LayoutComponentType.QUESTIONNAIRE_REVIEW && component.questionnaireReview.content"
      :content="component.questionnaireReview.content"
    />
    <error-box v-else-if="component.type !== LayoutComponentType.GOAL"/>
  </template>
</template>
