<script lang="ts" setup>
import type {Questionnaire} from '@/ts/types/dto/interventions-dto';
import ReadOnlyQuestion from '@/vue/molecules/read-only/questionnaire/read-only-question.vue';
import ReadOnlyCollapsableHeader from '@/vue/molecules/read-only/helpers/read-only-collapsable-header.vue';

defineProps<{
  questionnaire: Questionnaire;
}>();
</script>

<template>
  <read-only-collapsable-header active default-opened>
    <template #header>
      <div class="ro-questionnaire-header">
        <div v-if="questionnaire.name" class="ro-questionnaire-title">
          {{ questionnaire.name }}
        </div>
        <template v-if="questionnaire.score">
          <div v-html="questionnaire.score.summary"/>
          <b class="result-text">{{ $t('result') }}</b>
          <div v-if="questionnaire.score" class="res-body">
            {{ questionnaire.score.score }} - {{ questionnaire.score.label }}
          </div>
        </template>
      </div>
    </template>
    <read-only-question
      v-for="question in questionnaire.questions"
      :key="question.userComponentId"
      :question="question"
    />
  </read-only-collapsable-header>
</template>
<style lang="scss" scoped>
.ro-questionnaire-header {
  flex: 1;
}

.ro-questionnaire-title {
  @include body-medium-3;
  margin-bottom: var(--Spacing-spacing-3);
}

.result-text {
  margin-top: var(--Spacing-spacing-4);
  display: block;
}

.res-body {
  background-color: var(--Neutral-UI-neutral-ui-20);
  border-radius: var(--button-border-radius);
  color: var(--Neutral-UI-neutral-ui-100);
  @include body-medium-3;
  margin: var(--Spacing-spacing-3) 0;
  padding: var(--Spacing-spacing-2);
  width: fit-content;
  height: fit-content;

  @include screen-md {
    padding: var(--Spacing-spacing-3) var(--Spacing-spacing-7);
  }
}
</style>
