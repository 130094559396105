<!-- eslint-disable sonarjs/no-identical-expressions -->
<script setup lang="ts">
import {useApi} from '@/ts/composables/stateful/use-api';
import InterventionShort from '@/vue/organisms/intervention-short.vue';
import {PublicationState} from '@/ts/types/dto/interventions-dto';
import {useLoadingDataRefNull} from '@/ts/composables/pure/use-loading-data-ref';
import {computed} from 'vue';
import DescriptionLeftEnter from '@/vue/molecules/description-left-enter.vue';
import LoadingError from '@/vue/templates/loading-error.vue';
import {useRouter} from 'vue-router';
import BalmDialog from '@/vue/atoms/balm-dialog.vue';
import {InterventionRouteName, pagesPath} from '@/ts/router/pages-path';


const SORTING_ORDER = [
  PublicationState.COMPLETED,
  PublicationState.IN_PROGRESS,
  PublicationState.NEW,
  PublicationState.ACTIVE,
  PublicationState.PREVIEW,
  PublicationState.MAINTENANCE,
  PublicationState.DISCONTINUED,
  PublicationState.WITHDREW,
  PublicationState.NOT_JOINED,
];

const router = useRouter();
const api = useApi();
const [data, loading, error] = useLoadingDataRefNull(async() => api.me.getInterventions());

const interventions = computed(() => {
  if (data.value) {
    const unsorted = data.value;
    return unsorted.sort((aa,bb) => SORTING_ORDER.indexOf(aa.publicationState) - SORTING_ORDER.indexOf(bb.publicationState));
  }
  return [];
});

const showInterventionDetails = computed(() => 
  router.currentRoute.value.matched.some(route => route.name === InterventionRouteName.interventionInfo));

function closeModal(): void {
  // mount lifecycle doesn't trigger onOpen, so no value check required
  void router.push(pagesPath.intervention.interventionsList);
};
</script>

<template>
  <div class="page">
    <loading-error id="intevention-list" :loading="loading" type="page" :error="error">
      <description-left-enter :name="$t('paths')" size="h1"/>
      <div class="list">
        <intervention-short
          v-for="intervention in interventions"
          :key="intervention.id"
          :intervention="intervention"
        />
      </div>

      <balm-dialog :model-value="showInterventionDetails" theme="grey-10" @update:model-value="closeModal">
        <router-view name="modal"/>
      </balm-dialog>
    </loading-error>
  </div>
</template>
<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--Spacing-spacing-3);
  justify-content: left;
  height: 100%;

  @include screen-lg {
    gap: var(--Spacing-spacing-4);
  }
}

.page {
  @include q-page;
}
</style>
