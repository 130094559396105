<script setup lang="ts">
/* eslint-disable max-lines */
import {computed} from 'vue';
import {
  type Intervention,
  type Page,
  type Session,
  SessionState,
} from '@/ts/types/dto/interventions-dto';
import {getVueLink} from '@/ts/composables/stateful/use-route-params';
import {pagesPath} from '@/ts/router/pages-path';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import {useSession} from '@/ts/composables/pure/use-provided-session';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {useProvidedIntervention} from '@/ts/composables/pure/use-provided-intervention';
import {SHOW_UNLOCK_BTN} from '@/ts/utils/consts';
import SessionUnlocks from '@/vue/molecules/session-summary/session-unlocks.vue';
import {useLoadingNotification} from '@/ts/composables/pure/use-loading-notification';
import {useApi} from '@/ts/composables/stateful/use-api';
import AddToCalendarBtn from '@/vue/molecules/add-to-calendar-btn.vue';

const props = defineProps<{
  sessionId: UuidDTO;
  intervention: Intervention
}>();

const intervention = computed<Intervention>(() => props.intervention);
const {currentSession} = useProvidedIntervention(intervention);
const pageSession = computed<Session>(() => props.intervention.sessions.find(ses => props.sessionId === ses.id)!);
const {lastPageIndexCompleted} = useSession(intervention, pageSession);

const api = useApi();

const nextSummaryLink = computed<string | null>(() => {
  return currentSession.value ? getVueLink(pagesPath.session.sessionSummary, {
    iid: props.intervention.id,
    sid: currentSession.value!.id,
  }) : null;
});

const showGoToNext = computed(() =>
  pageSession.value.state === SessionState.COMPLETED
  && nextSummaryLink.value !== null);

const [unblockSession, unlockingInProgress] = useLoadingNotification(async() => api.me.unlockSession(props.intervention.id), () => {
  window.location.reload();
});

const goToCurrentInfo = computed<{ page: Page, link: string } | null>(() => {
  if (pageSession.value?.state !== SessionState.UNLOCK) {
    return null;
  }
  let index = 0;
  if (lastPageIndexCompleted.value !== null
    && lastPageIndexCompleted.value < pageSession.value?.pages.length) {
    index = lastPageIndexCompleted.value;
  }
  const link = getVueLink(pagesPath.session.sessionComponent, {
    iid: props.intervention.id,
    sid: currentSession.value!.id,
    pid: index + 1,
  });

  return {
    page: pageSession.value?.pages[index],
    link,
  };
});
</script>
<template>
  <div v-if="goToCurrentInfo" class="sess-home-next-blue">
    <div class="left-sess-home-next">
      <div class="next-up">
        {{ $t('next_up_page', {name: goToCurrentInfo.page.name}) }}
      </div>
      <div v-if="goToCurrentInfo.page.description" v-html="goToCurrentInfo.page.description"/>
    </div>
    <balm-btn
      class="cta"
      theme="white"
      :label="$t('continue')"
      icon="arrow-narrow-right.svg"
      :to="goToCurrentInfo.link!"
      paddings="p3-7"
    />
  </div>
  <balm-btn
    v-else-if="showGoToNext"
    theme="blue"
    :label="$t('continue')"
    :to="nextSummaryLink!"
    paddings="p3-7"
  />
  <div v-else-if="currentSession?.id === pageSession?.id" class="sess-home-next-white">
    <div>
      <div class="next-up">
        {{ $t('next_up', {page: currentSession!.pages[0].name}) }}
      </div>
      <add-to-calendar-btn
        v-if="currentSession!.timeUntilUnlockMillis"
        class="calendar-btn"
        :title="$t('next_balm_session')"
        description="https://balm.significo.com"
        :event-id="currentSession.id"
        :start-time="currentSession!.unlockDate"
        :end-time="currentSession!.unlockDate"
      />
    </div>
    <div class="unlocks">
      <session-unlocks :session="currentSession"/>
    </div>
    <balm-btn
      v-if="SHOW_UNLOCK_BTN && pageSession.state === SessionState.LOCK"
      :loading="unlockingInProgress"
      :label="$t('unlock_now')"
      @click="unblockSession"
    />
  </div>
</template>
<style lang="scss" scoped>
.sess-home-next-blue {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-spacing-2);
  justify-content: space-between;
  border-radius: var(--card-border-radius);
  background-color: var(--Secondary-secondary-color-1);
  padding: var(--Spacing-spacing-4) var(--Spacing-spacing-4) var(--Spacing-spacing-4) var(--Spacing-spacing-4);
  container-type: inline-size;
  @include screen-md {
    flex-direction: row;
  }
  @include screen-lg {
    padding: var(--Spacing-spacing-7) var(--Spacing-spacing-9) var(--Spacing-spacing-7) var(--Spacing-spacing-7);
  }
}

.sess-home-next-white {
  border-radius: var(--card-border-radius);
  background-color: var(--Neutral-UI-neutral-ui-0);
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);
  padding: var(--Spacing-spacing-4) var(--Spacing-spacing-4) var(--Spacing-spacing-4) var(--Spacing-spacing-4);

  @include screen-sm {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  @include screen-lg {
    padding: var(--Spacing-spacing-7) var(--Spacing-spacing-9) var(--Spacing-spacing-7) var(--Spacing-spacing-7);
  }
}

.next-up {
  @include heading-5;
}

.calendar-btn {
  margin-top: var(--Spacing-spacing-7);
}

.left-sess-home-next {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-2);
  justify-content: space-between;
}

.cta {
  width: 100%;
  @include screen-sm {
    width: fit-content;
  }
}

.unlocks {
  width: fit-content
}
</style>
