<script lang="ts" setup>
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';
import type {
  ContentFAQ,
  ContentGlossary,
  ContentResource,
} from '@/ts/types/dto/home-dto';
import {
  type BalmSidebarMenuItem,
  BalmSidebarType,
} from '@/ts/types/component/balm-sidebar';
import SidebarFaq from '@/vue/molecules/sidebar/sidebar-faq.vue';
import SidebarGlossary from '@/vue/molecules/sidebar/sidebar-glossary.vue';
import SidebarResources from '@/vue/molecules/sidebar/sidebar-resources.vue';
import SidebarSessionRecap from '@/vue/organisms/sidebar/sidebar-session-recap.vue';
import SidebarLegaldocs from '@/vue/molecules/sidebar/sidebar-legaldocs.vue';
import type {LegalDocItem} from '@/ts/types/dto/tos-dto';
import {useDrawerStore} from '@/ts/store/drawer-store';
import {storeToRefs} from 'pinia';
import BalmSidebarToolbar from '@/vue/organisms/sidebar/balm-sidebar-toolbar.vue';

defineProps<{
  resources: ContentResource[];
  faq: ContentFAQ[];
  glossaries: ContentGlossary[];
  imprint: LegalDocItem;
  sidebarMenuItems: BalmSidebarMenuItem[];
}>();

const drawerStore = useDrawerStore();
const {balmSidebarState} = storeToRefs(drawerStore);

</script>
<template>
  <div class="right-bar-spacer">
    <div class="right-bar-holder" :class="{'right-bard-opened': !!balmSidebarState}">
      <div class="right-bar-content">
        <balm-sidebar-toolbar class="balm-sidebar-toolbar" :sidebar-menu-items="sidebarMenuItems"/>
        <!--transition delays dissapearing sidebar on close while animation, thus it's required -->
        <transition name="fade-slide">
          <div v-if="balmSidebarState" class="right-bar-tab">
            <sidebar-session-recap v-if="balmSidebarState === BalmSidebarType.SESSION_RECAP"/>
            <sidebar-glossary v-else-if="balmSidebarState === BalmSidebarType.GLOSSARY" :glossaries="glossaries"/>
            <sidebar-faq v-else-if="balmSidebarState === BalmSidebarType.FAQ" :faq="faq"/>
            <sidebar-resources v-else-if="balmSidebarState === BalmSidebarType.RESOURCES" :resources="resources"/>
            <sidebar-legaldocs v-else-if="balmSidebarState === BalmSidebarType.IMPRINT" :imprint="imprint"/>
          </div>
        </transition>
        <flat-icon-btn icon="x-close.svg" class="close-sidebar" @click="balmSidebarState = null"/>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
$sidebar-icons-width: 72px;
$sidebar-content-width: 800px;
$sidebar-total-max-width: 95vw;
$transition-duration: 0.4s;

.right-bar-spacer {
  position: relative;
  @include screen-lg {
    width: $sidebar-icons-width;
    flex-shrink: 0;
    margin-left: auto;
  }
}

.balm-sidebar-toolbar {
  display: none;
  @include screen-lg {
    display: flex;
    width: $sidebar-icons-width;
  }
}

.right-bar-tab {
  width: 100vw;
  max-height: 100%;
  padding: 0 var(--Spacing-spacing-4);
  overflow: auto;

  @include screen-md {
    width: $sidebar-content-width;
    max-width: 90vw;
    padding: 0 var(--Spacing-spacing-5);
  }
}

.close-sidebar {
  position: absolute;
  top: var(--Spacing-spacing-4);
  right: 0;

  @include screen-md {
    top: var(--Spacing-spacing-5);
    right: var(--Spacing-spacing-5);
  }
}

.right-bar-holder {
  right: 0px;
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: var(--Neutral-UI-neutral-ui-10);
  width: 0;
  flex-shrink: 0;
  position: absolute;
  transition: width $transition-duration ease-in-out, box-shadow $transition-duration ease-in-out;
  overflow: hidden;

  @include screen-lg {
    width: $sidebar-icons-width;
  }

  &.right-bard-opened {
    width: 100vw;
    z-index: 2; // bigger than z-index 1 on q-slider
    box-shadow: calc(var(--Spacing-spacing-1) * -1) 0px var(--Spacing-spacing-3) rgba(0, 0, 0, 0.2); /* Add shadow to create depth */

    @include screen-md {
      max-width: $sidebar-total-max-width;
      width: $sidebar-icons-width + $sidebar-content-width;
    }
  }
}

.right-bar-content {
  display: flex;
  height: 100%;
  position: relative;
  max-height: 100%;
  flex-direction: row;
  width: 100vw;

  @include screen-md {
    max-width: $sidebar-total-max-width;
    width: $sidebar-icons-width + $sidebar-content-width;
  }
}

.fade-slide-enter-from, .fade-slide-leave-to {
  opacity: 0;
}

.fade-slide-enter-to, .fade-slide-leave-from {
  opacity: 1;
}

.fade-slide-enter-active, .fade-slide-leave-active {
  transition: opacity $transition-duration ease-in-out;
}
</style>
