<script setup lang="ts">
import type {Content} from '@/ts/types/dto/interventions-dto';
import ContentBlock from '@/vue/molecules/components/helpers/content-block.vue';
import type {ComponentTheme} from '@/ts/types/component/layout-component-types';

defineProps<{
  content: Content;
  theme: ComponentTheme;
}>();


</script>
<template>
  <content-block
    :name="content.name"
    :audio="content.audio"
    :class="`content-component-${theme}`"
    :image="content.image"
    :display-type="content.displayType"
    :content="content.contentHTML"
    :theme="theme"
  />
</template>
<style scoped lang="scss">
.content-component-card {
  @include q-card-white;
  padding: var(--Spacing-spacing-4);
  @include screen-lg {
    padding: var(--Spacing-spacing-7);
  }
}

.content-component-raw {
  padding: 0;
}
</style>
