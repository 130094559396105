<script lang="ts" setup>
import type {NavigationItem} from '@/ts/types/component/navigation';
import UserMenu from '@/vue/organisms/header/user-menu.vue';
import HeaderLogo from '@/vue/organisms/header/header-logo.vue';
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';
import {ref, watch} from 'vue';
import {useRouter} from 'vue-router';
import {useDrawerStore} from '@/ts/store/drawer-store';
import MenuBar from '@/vue/organisms/header/menu-bar.vue';
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';
import {useOopsieStore} from '@/ts/store/oopsie.store';
import {storeToRefs} from 'pinia';
import type {BalmSidebarMenuItem} from '@/ts/types/component/balm-sidebar';

defineProps<{
  mainNavigationItems?: NavigationItem[];
  sidebarMenuItems: BalmSidebarMenuItem[];
}>();

defineEmits<{
  clickMainNavigationItem: [string],
}>();

const drawerStore = useDrawerStore();

const router = useRouter();
watch(router.currentRoute, () => {
  drawerStore.closeHamburgerMenuDrawer();
  drawerStore.closeUserDrawer();
});

const {screenSize, isMobileResolution} = useScreenSize();
watch(screenSize, () => {
  drawerStore.closeHamburgerMenuDrawer();
  drawerStore.closeUserDrawer();
});

const hamburgerMenuButton = ref<typeof FlatIconBtn>();
function onFocusHamburgerMenuButton(): void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  hamburgerMenuButton.value?.$el?.focus();
};

const oopsieStore = useOopsieStore();
const {oopsPageTemplateVisible} = storeToRefs(oopsieStore);

</script>
<template>
  <header class="balm-header" :class="{'balm-header--oopsie-page': oopsPageTemplateVisible}">
    <header-logo class="logo-order"/>

    <flat-icon-btn
      v-if="isMobileResolution"
      ref="hamburgerMenuButton"
      icon="hamburger-menu.svg"
      class="hamburger-btn-order"
      :label="$t('aria_open_navigation')"
      @click="drawerStore.openHamburgerMenuDrawer()"
    />

    <div class="menus-container menus-container-order">
      <menu-bar
        :sidebar-menu-items="sidebarMenuItems"
        :main-navigation-items="mainNavigationItems"
        @clickMainNavigationItem="$emit('clickMainNavigationItem', $event)"
        @requestOpenerFocus="onFocusHamburgerMenuButton"
      />

      <user-menu/>
    </div>
  </header>
</template>
<style lang="scss" scoped>
.balm-header {
  background-color: var(--Neutral-UI-neutral-ui-20);
  padding: 0 var(--Spacing-spacing-2);
  display: flex;
  height: var(--header-height);
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  align-self: stretch;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -12px;
    width: 13px;
    height: 13px;
    mask-size: 51% 51%;
    mask-repeat: no-repeat;
    background-color: var(--Neutral-UI-neutral-ui-20);

    @include screen-lg {
      content: none;
    }
  }

  &:before {
    left: 0;
    mask: radial-gradient(circle 13px at bottom right, #0000 95%, #000) top left;
  }

  &:after {
    right: 0;
    mask: radial-gradient(circle 13px at bottom left, #0000 95%, #000) top right;
  }

  @include screen-lg {
    background-color: var(--body-background-color);
    padding: var(--Spacing-spacing-4) var(--Spacing-spacing-5);
  }

  &--oopsie-page {
    background-color: var(--oopsie-page-background);
    &:before,
    &:after {
      content: none;
    }
  }
}

.logo-order {
  order: 2;
  @include screen-lg {
    order: 1;
  }
}

.hamburger-btn-order {
  order: 1;
  @include screen-lg {
    order: 2;
  }
}

.menus-container-order {
  order: 3;
}

.menus-container {
  display: flex;
  align-items: center;
  gap: var(--Spacing-spacing-9);
}

</style>
