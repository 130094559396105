<script lang="ts" setup>

withDefaults(defineProps<{
  active: boolean;
  defaultOpened?: boolean
}>(), {
  defaultOpened: false,
});

defineSlots<{
  default: any;
  header: any;
}>();

</script>

<template>
  <q-expansion-item
    class="read-only-collapsable"
    :class="{'user-answer': active, info: !active}"
    :default-opened="defaultOpened"
    header-class="read-only-collapsable-header"
  >
    <template #header>
      <slot name="header"/>
    </template>
    <div class="read-only-collapsable-body">
      <slot/>
    </div>
  </q-expansion-item>
</template>
<style lang="scss" scoped>
.read-only-collapsable {
  :deep(.read-only-collapsable-header) {
    background: var(--Neutral-UI-neutral-ui-10);
    padding: var(--Spacing-spacing-3);
    border-radius: var(--card-border-radius);

    @include screen-md {
      padding: var(--Spacing-spacing-5) var(--Spacing-spacing-7);
    }
  }

  &.info :deep(.read-only-collapsable-header) {
    border-left: 6px solid var(--Neutral-UI-neutral-ui-30);
  }

  &.user-answer :deep(.read-only-collapsable-header) {
    border-left: 6px solid var(--Primary-primary-color-2);
  }
}

.read-only-collapsable-body {
  margin: var(--Spacing-spacing-1) 0 0 var(--Spacing-spacing-3);
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-1);

  @include screen-md {
    margin: var(--Spacing-spacing-1) 0 0 var(--Spacing-spacing-7);
  }
}
</style>
