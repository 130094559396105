<script setup lang="ts">
import {ref, watch} from 'vue';

const props = defineProps<{
  slideIndex: number;
  totalSlides: number;
}>();

const direction = ref('left');

watch(() => props.slideIndex, (val: number, oldVal: number) => {
  direction.value = oldVal < val ? 'left' : 'right';
});

</script>
<template>
  <div class="slider">
    <template v-for="index in totalSlides" :key="index">
      <transition :name="direction">
        <div v-if="index - 1 === slideIndex" class="slide">
          <slot/>
        </div>
      </transition>
    </template>
  </div>
</template>
<style scoped lang="scss">
.right-enter-active, .right-leave-active, .left-enter-active, .left-leave-active{
  transition: all .8s ease-in-out;
}

.left-leave-from, .left-enter-to {
   transform: translateX(0);
   opacity: 1;
}

.right-enter-from, .left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.right-leave-from, .right-enter-to {
   transform: translateX(0);
   opacity: 1;
}

.right-leave-to, .left-enter-from {
   transform: translateX(100%);
   opacity: 0;
}

.slider {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>
