
type CurrentElement = HTMLElement & {
  clickOutsideHandler?(event: MouseEvent): void;
};

export const clickOutside = {
  beforeMount(el: CurrentElement, binding: { value(event: MouseEvent): void }): void {
    el.clickOutsideHandler = (event: MouseEvent): void => {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event); // Call the provided callback
      }
    };
    document.addEventListener('click', el.clickOutsideHandler);
  },
  unmounted(el: CurrentElement): void {
    if (el.clickOutsideHandler) {
      document.removeEventListener('click', el.clickOutsideHandler);
      delete el.clickOutsideHandler; // Clean up to prevent memory leaks
    }
  },
};