export interface InputSliderItem {
  value: number;
  label: string;
}

export enum InputSliderMarkerStyle {
  HIDDEN = 'Hidden',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  DISPLAY_VALUE = 'DISPLAY_VALUE',
}

export enum InputSliderSelectionStyle {
  HIDDEN = 'HIDDEN',
  LABEL_ONLY = 'LABEL_ONLY',
  INDEX_WITH_LABEL = 'INDEX_WITH_LABEL',
  VALUE_WITH_LABEL = 'VALUE_WITH_LABEL',
}