<script setup lang="ts">
/*
 eslint-disable  max-lines
 */
import type {Questionnaire} from '@/ts/types/dto/interventions-dto';
import {computed, onBeforeMount, ref} from 'vue';
import {
  AnswerComponentType,
  type QuestionnaireAnswers,
  type SessionRequestQuestionnaire,
} from '@/ts/types/dto/session-request-dto';
import QuestionComponent from '@/vue/molecules/components/question/question-component.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import BalmDialog from '@/vue/atoms/balm-dialog.vue';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import BalmProgressCount from '@/vue/atoms/balm-progress-count.vue';
import QuestionnaireScore from '@/vue/molecules/components/questionnaire/questionnaire-score.vue';
import BalmSlider from '@/vue/atoms/balm-slider.vue';

const props = defineProps<{
  questionnaire: Questionnaire;
  loading: boolean;
}>();

const emit = defineEmits<{
  update: []
  finish: []
}>();

const model = defineModel<SessionRequestQuestionnaire[] | null>();
const isModalOpen = defineModel<boolean>('isModalOpen');

const currentQuestionIndex = ref(0);
const logger = useLogger();
const totalSteps = computed(() => {
  if (props.questionnaire.hasScore) {
    return props.questionnaire.questions.length + 1;
  }
  return props.questionnaire.questions.length;
});
const insideQuestionnaire = computed(() => !(props.questionnaire.hasScore && currentQuestionIndex.value === totalSteps.value - 1));

const value = computed<QuestionnaireAnswers | null>({
  get() {
    return model.value?.[currentQuestionIndex.value]?.questionnaireAnswers ?? null;
  },
  set(newValue) {
    const res = model.value ?? [];
    res[currentQuestionIndex.value] = {
      questionnaireAnswers: newValue!,
      type: AnswerComponentType.QUESTIONNAIRE,
    };
    model.value = [...res];
  },
});

function save(): void {
  logger.debug('Saving questionnaire')();
  currentQuestionIndex.value++;
  if (currentQuestionIndex.value === props.questionnaire.questions.length) {
    emit('update');
    if (!props.questionnaire.hasScore) {
      emit('finish');
      currentQuestionIndex.value--; // roll back from outside right value
    }
  } else if (currentQuestionIndex.value > props.questionnaire.questions.length) {
    emit('finish');
    currentQuestionIndex.value--; // roll back from outside right value
  }
}

onBeforeMount(() => {
  logger.debug(`onBeforeMont ${props.questionnaire.id}`)();
  const index = props.questionnaire.questions.findLastIndex((val) => val.isAnswered);
  currentQuestionIndex.value = index < 0 ? 0 : totalSteps.value - 1;
});

</script>

<template>
  <balm-dialog v-model="isModalOpen" type="h900" theme="grey-10" no-scroll>
    <div class="questionnaire-standard-modal">
      <q-form class="form" @submit="save">
        <h2 class="text-heading-1 headline">
          {{ questionnaire.name }}
        </h2>
        <div v-if="insideQuestionnaire" class="question">
          <balm-slider :slide-index="currentQuestionIndex" :total-slides="totalSteps">
            <question-component
              v-model="value"
              class="question-component"
              :questionnaire-id="questionnaire.id"
              :question="questionnaire.questions[currentQuestionIndex]"
              @ready-to-go-next="save"
            />
          </balm-slider>
        </div>
        <div v-else class="score">
          <q-skeleton v-if="loading" class="loading"/>
          <questionnaire-score v-else-if="questionnaire.score" :score="questionnaire.score"/>
        </div>
        <div :class="{progress: true, long: 5 <= questionnaire.questions.length}">
          <balm-progress-count :total="totalSteps" :current="currentQuestionIndex"/>
        </div>
        <div class="btns">
          <balm-btn :disable="currentQuestionIndex === 0" icon="arrow-left.svg" theme="grey" @click="currentQuestionIndex--"/>
          <balm-btn v-if="totalSteps - 1 !== currentQuestionIndex" type="submit" :label="$t('next')" icon="arrow-narrow-right.svg"/>
          <balm-btn
            v-else
            type="submit"
            :loading="loading"
            :label="$t('complete_assessment')"
            icon="check.svg"
            theme="green"
            :disable="loading"
          />
        </div>
      </q-form>
    </div>
  </balm-dialog>
</template>
<style scoped lang="scss">
.questionnaire-standard-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.form {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 10fr 1fr;
  flex-direction: column;
  gap: var(--Spacing-spacing-3);

  @include screen-md {
    grid-template-rows: 5fr 10fr 1fr;
    gap: var(--Spacing-spacing-3);
  }
}

.headline {
  grid-area: 1 / span 2;
  padding: var(--Spacing-spacing-4) var(--Spacing-spacing-8) 0 var(--Spacing-spacing-4);
}

.question {
  grid-area: 3 / span 2;
  height: 100%;

  @include screen-md {
    grid-area: 2 / span 2;
  }
}

.question-component {
  padding: 0 var(--Spacing-spacing-4);

  @include screen-md {
    padding: var(--Spacing-spacing-4);
  }
}

.score {
  grid-area: 3 / span 2;
  flex: 1;
  overflow-y: auto;
  padding: 0 var(--Spacing-spacing-4);

  @include screen-md {
    grid-area: 2 / span 2;
  }

  .loading {
    height: 100%;
  }
}

.progress {
  grid-area: 2 / span 2;
  display: flex;
  justify-content: center;
  padding: 0 var(--Spacing-spacing-4);
  width: 100%;

  @include screen-md {
    grid-area: 3 / 1;
    display: flex;
    justify-content: flex-start;
  }

  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &.long > .balm-progress-wrapper {
    width: 100%;
    justify-content: space-between;

    @include screen-md {
      justify-content: unset;
    }
  }
}

.btns {
  grid-area: 4 / span 2;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: var(--Spacing-spacing-1);
  padding: 0 var(--Spacing-spacing-4) var(--Spacing-spacing-4) var(--Spacing-spacing-4);

  @include screen-md {
    grid-area: 3 / 2;
    gap: var(--Spacing-spacing-5);
    display: flex;
    justify-content: flex-end;
    height: fit-content;
  }
}
</style>
