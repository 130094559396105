<script setup lang="ts">
import type {QuestionnaireReview} from '@/ts/types/dto/interventions-dto';
import ContentComponent from '@/vue/molecules/components/content-component.vue';
import {useApi} from '@/ts/composables/stateful/use-api';
import {
  onBeforeMount,
  ref,
} from 'vue';
import {useLoadingData} from '@/ts/composables/pure/use-loading-data-ref';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import LoadingError from '@/vue/templates/loading-error.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import type {ComponentTheme} from '@/ts/types/component/layout-component-types';

const props = defineProps<{
  review: QuestionnaireReview;
  iid: UuidDTO;
  sid: UuidDTO;
  theme: ComponentTheme;
}>();

const api = useApi();
const fetchedReview = ref<QuestionnaireReview | null>(null);

const [submit, loading, error] = useLoadingData(
  fetchedReview,
  async() => api.me.getQuestionnaireReview(props.iid, props.sid, props.review.id),
);

const logger = useLogger();
onBeforeMount(() => {
  logger.debug(`onBeforeMount ${props.review.id}`)();
  if (!props.review) {
    void submit();
  }
});

</script>
<template>
  <loading-error :id="`questionnaire-review-${sid}---${review.id}`" :loading="loading" :error="error" type="component">
    <template #loader>
      <q-skeleton :class="`skeleton-${theme}`"/>
    </template>
    <content-component :content="review?.content ?? fetchedReview?.content!" :theme="theme"/>
  </loading-error>
</template>
<style lang="scss" scoped>
.skeleton-card {
  height: 400px;
  @include q-card;
}

.skeleton-raw {
  height: 400px;
}
</style>

