<script lang="ts" setup>
import {
  computed,
  onBeforeMount,
  ref,
} from 'vue';
import type {QuasarOption} from '@/ts/types/component/select-multichoice';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import type {PrimaryFeeling} from '@/ts/types/dto/interventions-dto';

const model = defineModel<UuidDTO[]>();

const props = defineProps<{
  feeling: PrimaryFeeling;
}>();

const options = ref<QuasarOption<UuidDTO>[]>([]);
const allOptions = computed(() => {
  return [
    {
      label: props.feeling.name,
      value: props.feeling.id,
    },
    ...props.feeling.secondaryFeelings.map(sf => ({label: sf.name, value: sf.id}))];
});
onBeforeMount(() => {
  options.value = allOptions.value;
});

const value = computed<QuasarOption<UuidDTO>[]>({
  get() {
    return allOptions.value.filter(option => model.value!.includes(option.value));
  },
  set(newValue) {
    model.value = newValue.map(option => option.value);
  },
});

function filterFn(val: string, update: (arg: () => void) => void): void {
  if (val === '') {
    update(() => {
      options.value = allOptions.value;
    });
  } else {
    update(() => {
      options.value = allOptions.value.filter(item => item.label.toLowerCase().includes(val.toLowerCase()));
    });
  }
}
</script>
<template>
  <q-select
    v-model="value"
    use-input
    use-chips
    :options="options"
    multiple
    :label="props.feeling.name"
    name="feeling"
    @filter="filterFn"
  >
    <template #selected-item="scope">
      <q-chip
        removable
        :tabindex="scope.tabindex"
        :color="scope.opt.color"
        text-color="black"
        class="chip"
        size="md"
        @remove="scope.removeAtIndex(scope.index)"
      >
        {{ scope.opt.label }}
      </q-chip>
    </template>
  </q-select>
</template>

<style lang="scss" scoped>

.chip {
  background-color: var(--Secondary-secondary-color-2);
  margin: var(--Spacing-spacing-2) var(--Spacing-spacing-1) 0 0;
}

</style>
