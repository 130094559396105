<script lang="ts" setup>
import GlobalPageTemplate from '@/vue/templates/global-page-template.vue';
import OopsTemplate from '@/vue/templates/oops-template.vue';
</script>

<template>
  <global-page-template :sidebar-menu-items="[]">
    <oops-template
      :text="$t('this_page_doesnt')"
      text-style="light"
      error-type="not-found-404"
    />
  </global-page-template>
</template>
