<script setup lang="ts">
import {type Component, ComponentType, type PrimaryFeeling, type ThinkingTrap} from '@/ts/types/dto/interventions-dto';
import ContentComponent from '@/vue/molecules/components/content-component.vue';
import JournalComponent from '@/vue/molecules/components/journal-component.vue';
import type {
  SessionRequest,
  SessionRequestConceptualizationWorksheet,
  SessionRequestJournal,
  SessionRequestProblemSolvingWorksheet,
  SessionRequestQuestionnaire,
} from '@/ts/types/dto/session-request-dto';
import ErrorBox from '@/vue/molecules/error-box.vue';
import ConceptualizationComponent from '@/vue/molecules/components/conceptualization-component.vue';
import ProblemSolvingComponent from '@/vue/molecules/components/problem-solving/problem-solving-component.vue';
import QuestionnaireReviewComponent from '@/vue/molecules/components/questionnaire-review-component.vue';
import TableComponent from '@/vue/molecules/components/table-component.vue';
import QuestionnaireComponent from '@/vue/molecules/components/questionnaire/questionnaire-component.vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import LayoutComponent from '@/vue/molecules/components/layout-components.vue';
import {ComponentTheme} from '@/ts/types/component/layout-component-types';

// THIS component is highly cohesive with layout-component and layout-components
defineProps<{
  component: Component;
  primaryFeelings: PrimaryFeeling[];
  thinkingTraps: ThinkingTrap[];
  iid: UuidDTO;
  sid: UuidDTO;
  loading: boolean;
}>();

defineEmits<{
  update: [];
  finish: [];
}>();

const hideContinue = defineModel<boolean>('hideContinue');
const model = defineModel<SessionRequest[]>();

</script>
<template>
  <div>
    <layout-component
      v-if="component.type === ComponentType.LAYOUT"
      v-model="model"
      :layout="component.componentLayout"
      :loading="loading"
      :iid="iid"
      :sid="sid"
    />
    <table-component
      v-else-if="component.type === ComponentType.TABLE"
      :table="component.table"
      :theme="ComponentTheme.CARD"
    />
    <content-component
      v-else-if="component.type === ComponentType.CONTENT"
      :content="component.content"
      :theme="ComponentTheme.CARD"
    />
    <journal-component
      v-else-if="component.type === ComponentType.JOURNAL"
      v-model="model as SessionRequestJournal[]"
      :journal="component.journal"
      :theme="ComponentTheme.CARD"
    />
    <questionnaire-component
      v-else-if="component.type === ComponentType.QUESTIONNAIRE"
      v-model="model as SessionRequestQuestionnaire[]"
      v-model:hide-continue="hideContinue"
      :questionnaire="component.questionnaire"
      :loading="loading"
      :theme="ComponentTheme.CARD"
      @update="$emit('update')"
    />
    <questionnaire-review-component
      v-else-if="component.type === ComponentType.QUESTIONNAIRE_REVIEW"
      :iid="iid"
      :sid="sid"
      :theme="ComponentTheme.CARD"
      :review="component.questionnaireReview"
    />
    <problem-solving-component
      v-else-if="component.type === ComponentType.PROBLEM_SOLVING_WORKSHEET"
      v-model="model as SessionRequestProblemSolvingWorksheet[]"
      :worksheet="component.problemSolvingWorksheet"
    />
    <conceptualization-component
      v-else-if="component.type === ComponentType.CONCEPTUALIZATION_WORKSHEET"
      v-model="model as SessionRequestConceptualizationWorksheet[]"
      :loading="false"
      :primary-feelings="primaryFeelings"
      :thinking-traps="thinkingTraps"
      :worksheet="component.conceptualizationWorksheet"
    />
    <error-box v-else/>
  </div>
</template>
